import { For, createSignal, createMemo, splitProps } from "solid-js";
import { Stack, Card, Alert, Divider, } from "@suid/material";

import { PageListMenu } from '../SmallComponents/SmallListMenu';
import { customerTodoMenu } from "../../../utils/menuUtil"
import { isExtraSmall, isSmall } from "../../../utils/sizeUtil"

import { AvatarWithWfButtons, showWfCustomerJobsOpenItems, showWfCustomerToDoIncommingMatchesItems, showWfCustomerToDoGrossListItems, showWfCustomerToDoNotConfirmedInterviewItems, showWfCustomerToDoConfirmedInterviewItems, showWfCustomerToDoProposalItems, showWfCustomerToDoContractItems, showWfCustomerToDoOngoingItems } from '../SmallComponents/SmallAvatarWithWfButtons'
import { CardWithMinimize } from '../AppMenuBar/CardList'
import { getDummyAvatar } from '../../../utils/getdummyavatar'
import { getText } from '../SmallComponents/Translate'

type CardProps = {
    iconName: string
    header: string;
    avatarList: any[];
    showWfItemsFunction: any;
    defaultOpen: boolean;
    dummyText?: string;
};

const CustomerToDoComponent = () =>{
    const avatarGrossList = [
        { wfStatus: "Interview", candidateName: "Ida Idon", src: "/assets/imgs/avatar2.jpg" },
        { wfStatus: "Interview", candidateName: "Bo Flory", src: getDummyAvatar() },
        { wfStatus: "Interview", candidateName: "Anders And", src: getDummyAvatar() },
        { wfStatus: "Proposal", candidateName: "Nick Mathise", src: getDummyAvatar() },
        { wfStatus: "Proposal", candidateName: "Andy Theis", src: getDummyAvatar() },
        { wfStatus: "Contract", candidateName: "Ida Madson", src: getDummyAvatar() },
        { wfStatus: "Contract", candidateName: "Billy Turner", src: getDummyAvatar() },
        { wfStatus: "Contract", candidateName: "Edith Smith", src: getDummyAvatar() },
        { wfStatus: "Extension", candidateName: "Ray Bitter", src: getDummyAvatar() },
        { wfStatus: "Extension", candidateName: "Tina Turner", src: getDummyAvatar() },
        { wfStatus: "Termination", candidateName: "Will Smith", src: getDummyAvatar() },
        { wfStatus: "Termination", candidateName: "Ray Bitter", src: getDummyAvatar() },
    ]

    const avatarWfContracts = [
        { wfStatus: "Contract", candidateName: "Ida Madson", jobName: "S/4 upgrade", src: "/assets/imgs/avatar2.jpg" },
        { wfStatus: "Contract", candidateName: "Billy Turner", jobName: "Welcome music", role: "Senior Developer", src: getDummyAvatar() },
        { wfStatus: "Contract", candidateName: "Edith Smith", src: getDummyAvatar() },
        { wfStatus: "Extension", candidateName: "Ray Bitter", src: getDummyAvatar() },
        { wfStatus: "Extension", candidateName: "Tina Turner", src: getDummyAvatar() },
        { wfStatus: "Termination", candidateName: "Will Smith", src: getDummyAvatar() },
        { wfStatus: "Termination", candidateName: "Ray Bitter", src: getDummyAvatar() },
    ]
    const avatarWfProposals = [
        { candidateName: "Nick Mathise", jobName: "S/4 upgrade", role: "Project Manager", src: getDummyAvatar() },
        { candidateName: "Andy Theis", jobName: "S/4 upgrade", role: "UI developer", src: getDummyAvatar() },
    ]
    const avatarWfNotConfirmedInterviews = [
        { candidateName: "Ida Idon", jobName: "S/4 upgrade, Project Manager", wfStatus: "Interview not Scheduled", src: getDummyAvatar() },
    ]
    const avatarWfConfirmedInterviews = [
        { candidateName: "Bo Flory", jobName: "PI Developer", wfStatus: "Interview 12/4/23 16:00", src: getDummyAvatar() },
        { candidateName: "Anders And", jobName: "S/4 upgrade, Project Manager", wfStatus: "Interview 23/2/23 11:00 AM", src: getDummyAvatar() },
    ]
    const avatarWfIncommingMatches = [
        { candidateName: "Berta Bernstof", jobName: "HR Upgrade", role: "Project Manager", src: getDummyAvatar() },
    ]

    const avatarWfMatchingNotStarted = [
        { candidateName: "", jobName: "HR Upgrade", role: "Project Manager", src: "" },
        { candidateName: "", jobName: "HR Upgrade", role: "Tech Lead", src: "" },
        { candidateName: "", jobName: "HR Upgrade", role: "HR Functional Expert", src: "" },
    ]


    let numberTotalAvatars = 0, numberContracts = 0, numberProposals = 0, numberComfirmedInterviews = 0, numberNotComfirmedInterviews = 0, numberOpenMatches = 0, numberOngoingMatches = 0
    createMemo(() => {
        numberTotalAvatars = avatarGrossList.length
        numberContracts = avatarWfContracts.length
        numberProposals = avatarWfProposals.length
        numberComfirmedInterviews = avatarWfConfirmedInterviews.length
        numberNotComfirmedInterviews = avatarWfNotConfirmedInterviews.length
        numberOngoingMatches = avatarWfIncommingMatches.length
        numberOpenMatches = avatarWfMatchingNotStarted.length
    })

    const txtNumbersProposals = getText("todopage", "numbershaveproposal", [{ name: "Number", value: String(numberProposals) }])
    const txtNumbersContract = getText("todopage", "numbersreadytosign", [{ name: "Number", value: String(numberContracts) }])
    const txtNumbersNotConfirmedInterviews = getText("todopage", "numbersnotconfirmedinterviews", [{ name: "Number", value: String(numberNotComfirmedInterviews) }])

    const txtNumbersUpcommingInterviews = getText("todopage", "numbersupcomminginterviews", [{ name: "Number", value: String(numberComfirmedInterviews) }])
    const txtNumbersOngoingMatches = getText("todopage", "numbersmatchingwip", [{ name: "Number", value: String(numberOngoingMatches) }])
    const txtNumbersOpenMatches = getText("todopage", "numbersmatchingsnotstarted", [{ name: "Number", value: String(numberOpenMatches) }])

    const txtMatchingNotStarted = getText("jobpage", "openroles")
    const txtIncommingMatches = getText("todopage", "ongoingmatches")
    const txtPlanInterviews = getText("todopage", "notacceptedinterviews")
    const txtConfirmInterviews = getText("todopage", "upcomminginterviews")
    const txtProposals = getText("default", "proposals")
    const txtContracts = getText("default", "contracts")
     

    const [openFilter, setOpenFilter] = createSignal(false);
    const handleOpenFilter = () => {
        alert('handleOpenFilter')
        setOpenFilter(true);
    }

    const avatarSize = (isExtraSmall() || isSmall()) ? "medium" : "large";


    const RenderCard = (props: CardProps) => {
        const [local] = splitProps(props, ['dummyText'])

        return (
            <CardWithMinimize iconName={props.iconName} header={props.header + " (" + props.avatarList.length + ")"} type="stack" defaultOpen={props.defaultOpen}>
                <For each={props.avatarList}>
                    {(item) => (
                        <AvatarWithWfButtons
                            avatarSize="medium"
                            showWfItems={props.showWfItemsFunction}
                            avatarName={item.candidateName ? item.candidateName : item.role}
                            jobName={item.jobName}
                            jobStartDate={item.jobStartDate}
                            jobEndDate={item.jobEndDate}
                            onSiteText={item.onSiteText}
                            wfStatus={item.wfStatus}
                            role={item.role}
                            src={item.src}
                        />
                    )}
                </For>
            </CardWithMinimize>
        );
    };

    return (
        <>


            {/* Present Job section for Customer */}

            <Card sx={{ margin: 1 }}>

                {/* First row of the profile section */}
                <Stack
                    direction="row"
                    alignItems="left"
                    spacing={2}
                    sx={{ margin: 1, display: 'flex' }}
                >
                    {/* Addition navigation options for the customer */}

                    <PageListMenu options={customerTodoMenu} handleEvent={handleOpenFilter} />

                </Stack>

                <Divider />

                {/* Relevant info for the candidate's other activities */}

                <Alert sx={{ px: 1, py: 1 }} severity="info">
                    {txtNumbersNotConfirmedInterviews()}. {txtNumbersUpcommingInterviews()}. {txtNumbersOngoingMatches()}. {txtNumbersProposals()}. {txtNumbersContract()}. {txtNumbersOpenMatches()}.
                </Alert>

                {/* Roles defined for job */}
                <Card sx={{ margin: 2, display: 'flex' }}>
                    <Stack >
                        <Stack sx={{ display: 'flex' }} direction="row" alignItems="center" spacing={1}
                        >
                            <For each={avatarGrossList}>
                                {(item) =>
                                    <Stack sx={{ alignItems: 'center' }}  >

                                        {<AvatarWithWfButtons showWfItems={showWfCustomerToDoGrossListItems} avatarSize="small" avatarName={""} wfStatus={item.wfStatus} jobName={item.candidateName} role={""} src={item.src} />}
                                    </Stack>
                                }
                            </For>
                        </Stack>
                    </Stack>
                </Card>

                <Divider />
 
                {/* Show a card for each group */}
                <RenderCard dummyText="d" iconName="matching" header={txtMatchingNotStarted() as string} defaultOpen={isExtraSmall() ? false : true} avatarList={avatarWfMatchingNotStarted} showWfItemsFunction={showWfCustomerJobsOpenItems} />
                <RenderCard dummyText="d" iconName="matching" header={txtIncommingMatches() as string} defaultOpen={isExtraSmall() ? false : true} avatarList={avatarWfIncommingMatches} showWfItemsFunction={showWfCustomerToDoIncommingMatchesItems} />
                <RenderCard dummyText="d" iconName="interview" header={txtPlanInterviews() as string} defaultOpen={isExtraSmall() ? false : true} avatarList={avatarWfNotConfirmedInterviews} showWfItemsFunction={showWfCustomerToDoNotConfirmedInterviewItems} />
                <RenderCard dummyText="d" iconName="interview" header={txtConfirmInterviews() as string} defaultOpen={isExtraSmall() ? false : true} avatarList={avatarWfConfirmedInterviews} showWfItemsFunction={showWfCustomerToDoConfirmedInterviewItems} />
                <RenderCard dummyText="d" iconName="proposal" header={txtProposals() as string} defaultOpen={false} avatarList={avatarWfProposals} showWfItemsFunction={showWfCustomerToDoProposalItems} />
                <RenderCard dummyText="d" iconName="contract" header={txtContracts() as string} defaultOpen={false} avatarList={avatarWfContracts} showWfItemsFunction={showWfCustomerToDoContractItems} />
            </Card>
            <br />
            <br />
        </>
    );
}
export { CustomerToDoComponent }