import { PageTemplate } from './PageTemplate'
import { WFMatchingButtons } from '../components/Workflow/WfActionsComponent';
import { CustomerToDosComponent } from '../components/Customer/CustomerToDosComponent'
import { CustomerToDoComponent } from '../components/Customer/CustomerToDoComponent'

const ShowCustomerToDoPage = () => {
    return (
        <PageTemplate
            type="customer" headerPage="todopage" headerElement="header"
            leftPaneComponent={CustomerToDosComponent()} mainComponent={CustomerToDoComponent()} buttonComponent={WFMatchingButtons()} >
        </PageTemplate>

    );
}
export { ShowCustomerToDoPage }
